import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import * as Styles from "../components/Layout/styles"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Styles.ErrorPage>
      <Styles.Image404 />
      <Styles.Content404>
        <Styles.TextError>
          Ops! <br /> Algo de errado não está certo!
        </Styles.TextError>
        <Styles.Subtitle>
          Parece que não encontramos a página que você estava <br /> procurando.
        </Styles.Subtitle>
        <Link to="/">
          <Styles.ButtonSecondary>
            <label>Voltar para o início</label>
          </Styles.ButtonSecondary>
        </Link>
      </Styles.Content404>
    </Styles.ErrorPage>
  </Layout>
)

export default NotFoundPage
